<template>
  <div>
    <video autoplay muted loop id="myVideo">
      <source src="../../public/video/video.mp4" type="video/mp4" />
    </video>
    <v-app-bar color="black" height="80px" width="100vw" class="yes">
      <img class="login__img" :src="image" />
    </v-app-bar>
    <div class="login">
      <Login />
    </div>
  </div>
</template>

<script>
import image from "../assets/images/design/logo.svg";
import Login from "@/components/Login";

export default {
  components: { Login },
  data: () => ({
    image,
  }),
};
</script>

<style lang="scss" scoped>
#myVideo {
  display: none;
  position: fixed;
  object-fit: cover;

  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;

  @media screen and (min-width: 768px) {
    display: block;
  }
}
</style>
