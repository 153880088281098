<template>
  <v-card style="max-width: 400px; background-color: #fff">
    <div class="login__title">
      Login to The Kiosko
      <br />
      {{ this.$vars.TITLE_ROOM }}
    </div>

    <v-card-text>
      <v-form @submit.prevent="login">
        <div class="login__inputs">
          <v-text-field
            label="Phone number"
            v-model="form.username"
            v-validate="'required|numeric|min:4|max:10'"
            data-vv-name="phone"
            color="black"
            :error-messages="errorsBags.collect('phone')"
          />
          <v-text-field
            label="Password"
            v-model="form.email"
            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPass ? 'text' : 'password'"
            v-validate="'required'"
            data-vv-name="password"
            color="black"
            style="margin-top: -15px"
            :error-messages="errorsBags.collect('password')"
            @click:append="showPass = !showPass"
          />
        </div>
        <button type="submit" class="login__button">Log In</button>
        <div class="login__forgot" @click="redirectToForgot">
          Forgot password
        </div>
        <div v-if="show4Digit" class="login__signup">
          <button class="login__signup__button" @click="redirectToSignup4Digit">
            Create 4 Digit Account
          </button>
          <button class="login__signup__button" @click="redirectToSignup">
            Create Account
          </button>
        </div>
        <div v-else class="login__signup">
          <div class="login__signup__text">
            Don’t have an account? Go to the sign up page.
          </div>
          <button class="login__signup__button" @click="redirectToSignup">
            Create Account
          </button>
        </div>
        <a class="login__redirect" v-show="!cashAvailable" href="https://thekiosko.com">
          &#8592; Back to thekiosko.com</a
        >
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Login",
  data: () => ({
    form: {
      username: "",
      email: "",
    },
    showPass: false,
    show4Digit: false,
    cashAvailable: localStorage.cashAvailable === 'true',
  }),
  mounted() {
    this.show4Digit = this.$vars.SHOW_4_DIGIT === "true";
  },
  methods: {
    ...mapActions(["setIsAuthenticated"]),
    redirectToSignup() {
      this.$router.push({ name: "signup" });
    },
    redirectToSignup4Digit() {
      this.$router.push({ name: "signup-4digit" });
    },
    redirectToForgot() {
      this.$router.push({ name: "forgot" });
    },
    async login() {
      try {
        const isValid = await this.$validator.validate();
        if (isValid) {
          const payload = new FormData();
          payload.append("email", this.form.email);
          payload.append("username", this.form.username);

          const response = await this.$axios.post("/api/login", payload);

          if (response && response.status === 200) {
            document.cookie = `PHPSESSID=${response.data}`;
            this.setIsAuthenticated(true);
            this.$router.push({ name: "dashboard" });
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
